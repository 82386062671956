
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import PartnerSider from '../partnerSider';
const ClientsLogo = [
    "./images/partner-img/cisco.png",
  "./images/partner-img/sonicwall.png",
  "./images/partner-img/cyber.png",
  "./images/partner-img/forcepoint.png",
  "./images/partner-img/sophos.png",
  "./images/partner-img/juniper.png",
  "./images/partner-img/fortinet.png",
  "./images/partner-img/stormshield.png",
]
function Cybersecuritysolutions() {
    return (
        <div className='HardwareandSoftwares'>
               <br /> <br />
               <br /> 
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/ict.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                        Cyber security solutions
                    </h1>
                </div>
            </section>
            <br /> <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" >

                <Container >
                    <div class="row">
                        <div class="col-md-8">
                            <div className="intel-hardwere">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con">
                                            <p>Cybersecurity solutions are essential for protecting organizations and individuals from various cyber threats, including data breaches, malware, and unauthorized access. These solutions encompass a broad range of technologies and practices designed to safeguard information systems, networks, and data.</p>
                                        </div>
                                    </div>
                                    {/* <div class="col-md-4">
                                        <div className="intel-hardwere-img">
                                            <img src='./images/Resellers-of-IT-Hardware.webp' />
                                        </div>
                                    </div> */}
                                </div>
                                <div class="row">
                                    <h2 className='key-f'>Key Elements of Cybersecurity Solutions:</h2>
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con-h">
                                            <p><span>Intelcom Limited</span> We deliver resilient and secure digital ecosystems where individuals and organizations thrive with confidence and trust, even amid evolving cyber threats. Whether you're seeking cutting-edge tools, expert guidance, or a strategic partner, we provide the solutions you need.</p>
                                            <br/>
                                            <p>Intelcom’s comprehensive approach empowers organisations to identify risk, protect against known threats, detect suspicious activities, provide incident response, and enable organisations to recover from a breach or security incident.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="submit-form">
                                <h3 className='msg-h'>Drop a Message..</h3>
                                <div class="formbold-main-wrapper">

                                    <div class="formbold-form-wrapper">
                                        <form >
                                            <div class="formbold-mb-5">
                                                <label for="name" class="formbold-form-label"> Full Name </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Full Name"
                                                    class="formbold-form-input"

                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="email" class="formbold-form-label"> Email Address </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="subject" class="formbold-form-label"> Subject </label>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    placeholder="Enter your subject"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="message" class="formbold-form-label"> Message </label>
                                                <textarea
                                                    rows="6"
                                                    name="message"
                                                    id="message"
                                                    placeholder="Type your message"
                                                    class="formbold-form-input"
                                                ></textarea>
                                            </div>

                                            <div>
                                                <button class="formbold-btn">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intel-hardwere-img">
                        <img src='./images/cyber-security-page.webp' />
                    </div>

                </Container>
            </section>
            <br /><br />
            <section id="clients" class="clients section">
                <div class="container section-title" data-aos="fade-up">
                    <h2>Cyber Security Solutions Partnerships</h2>
                    <br /><br />
                    <PartnerSider ClientsLogo={ClientsLogo} />
                </div>
            </section>
            <br /><br />
        </div>
    );
}

export default Cybersecuritysolutions;