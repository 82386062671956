import React from "react";
import styled from "styled-components";
import "./index.scss"
// Sample service data


const Card = () => {
    return (

        <section class="promo-block ptb-100 mt--165 z-index position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo-block promo-hover-bg-1 hover-image shadow-lg p-5-1 custom-radius white-bg">
                            <div class="promo-block-icon mb-3">
                                
                                <span class="fab fa-superpowers icon-md color-primary"></span>
                            </div>
                            <div class="promo-block-content">
                                <h5>SIM card</h5>
                                <p>We offer an extensive range of SIM card options including standard SIM, Micro SIM, Nano SIM and even multiple SIMs on one card</p>
                            </div>
                            <a className="more-btn" href="/sim">More</a>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo-block promo-hover-bg-2 hover-image shadow-lg p-5-1 custom-radius white-bg">
                            <div class="promo-block-icon mb-3">
                                <span class="far fa-clock icon-md color-primary"></span>
                            </div>
                            <div class="promo-block-content">
                                <h5>Scratch card</h5>
                                <p>Unlock exclusive deals and discounts with our Scratch Card feature! After booking a service, scratch a virtual card to reveal exciting rewards </p>
                                  
                            </div>
                            <a className="more-btn" href="/scratchcard">More</a>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="single-promo-block promo-hover-bg-3 hover-image shadow-lg p-5-1 custom-radius white-bg">
                            <div class="promo-block-icon mb-3">
                                <span class="fas fa-headphones-alt icon-md color-primary"></span>
                            </div>
                            <div class="promo-block-content">
                                <h5>ICT Solutions</h5>
                                <p> These solutions would help maintain network integrity, enable smooth scaling, support seamless integration of new equipment, software.</p>
                                   
                            </div>
                            <a className="more-btn" href="/ictsolution">More</a>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    );
};

// Styled components for the service cards

const ServicesSection = styled.div`
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const ServiceCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-10px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 20px;
  h3 {
    margin-top: 0;
    font-size: 24px;
  }

  p {
    font-size: 16px;
    color: #666;
  }
`;

export default Card;
