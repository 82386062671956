import React from 'react';

function SIMcard() {
    return (
        <div className='SIMcard'>
  <br /> <br />
         
         <br /> 
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/sim/e-sim.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                        eSIM & SIM solutions
                    </h1>
                    <p className='white-text' style={{ color: "#fff" }}>SIM card manufacturing involves several stages, from designing the chip to embedding it into a plastic card. Here's a breakdown of the process:</p>
                </div>
            </section>
            <br /> <br />
            <br /> 
            <div class="section payments" id="Payments">
                <div class="container">
                    <h2 class="heading-tag">eSim</h2>
                    <h3 class="heading-1"><span>eSIM</span> management platform<br />
                        <span>with our integrated feature.</span>
                    </h3>
                    <div class="row">
                        <div class="col-sm-7">
                            <img src='images/sim/eSIM-orchestration-architecture.png' class="img-fluid" alt="" />
                        </div>
                        <div class="col-sm-5">
                            <hr class="style-1" />
                            <div class="mb-4">
                                <p>Connect new consumer and M2M IoT devices with the world’s first-ever cloud eSIM management solutions certified by GSMA’s Security Accreditation Scheme for Subscription Management. With a single, simplified, low cost, our secure, interoperable, scalable, and future-ready eSIM solutions can be quickly deployed to market so you can start selling eSIM in no time.</p>

                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
            <br />  <br />  <br />
         
         
         <br /> <br />
            <div class="section correspondence bg-color-8 bg-img-1" id="Correspondence">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-8">
                            <h2 class="heading-tag">Correspondence</h2>
                            <h3 class="heading-1">Real-time communication hub <span>with centralized inbox and priority tagging.</span></h3>
                            <hr class="style-1" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="service-box">
                                <img src="images/icons/4.png" alt="" />
                                <h5 class="heading">Design and management of artwork</h5>
                                <div>Design and management of artwork, organization, and handling of creative assets, ensuring they meet specific requirements, maintain brand consistency, and are used efficiently across different mediums. </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="service-box">
                                <img src="images/icons/5.png" alt="" />
                                <h5 class="heading">Creation of SIM profiles</h5>
                                <div>SIM Profile Creation refers to the process of configuring and setting up a SIM (Subscriber Identity Module) profile, which stores the necessary information to enable mobile devices to connect to a network, access services, and authenticate the user with their carrier</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="service-box">
                                <img src="images/icons/6.png" alt="" />
                                <h5 class="heading">Processing and verification of SIM data</h5>
                                <div>
                                SIM Data Handling and Verification are critical processes that ensure the secure and reliable use of a SIM (Subscriber Identity Module) card on a mobile network. Handling and verifying SIM data correctly is against unauthorized access.</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="service-box">
                                <img src="images/icons/7.png" alt="" />
                                <h5 class="heading">Personalization and embedding of chips</h5>
                                <div>Chip Personalization and Embedding refer to the processes used in manufacturing and configuring a chip (such as a SIM card, eSIM, or other secure embedded chips) to store personalized user data, security credentials, and application-specific settings.</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="service-box">
                                <img src="images/icons/8.png" alt="" />
                                <h5 class="heading">Production of final cards</h5>
                                <div>Production of final cards, refers to the final phase in creating a secure card, such as a SIM card, credit card, or ID card, where the personalized chip is integrated into its physical form and made ready for distribution. This phase involves embedding the chip into a plastic card, printing card details, adding security features</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="service-box">
                                <img src="images/icons/9.png" alt="" />
                                <h5 class="heading">Packaging and distribution</h5>
                                <div>Packaging and distribution refers to the process of designing and producing containers or wrappers for products. It serves multiple purposes, including protecting the product, facilitating storage and transportation, providing information to consumers, and enhancing the product's aesthetic appeal
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br /> <br />
         
         <br />
            <section class="customer-sim-section  --bgf ptb-10 bg-left-shape">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                        <div class="col-md-6 col-lg-6">
                            <div>
                            <img src='images/sim/eshim.png' style={{width:"100%"}} />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <h2>eSIM management platform</h2>
                            <div>
                                <p>Connect new consumer and M2M IoT devices with the world’s first-ever cloud eSIM management solutions certified by GSMA’s Security Accreditation Scheme for Subscription Management. With a single, simplified, low cost, our secure, interoperable, scalable, and future-ready eSIM solutions can be quickly deployed to market so you can start selling eSIM in no time.</p>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
          
            <br /> <br />
         
            <br /> <br />
        </div>
    );
}

export default SIMcard;
