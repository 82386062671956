import React from 'react';

function Scratchcard() {
    return (
        <div className='Scratchcard'>
              <br /> <br />
              <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/card/s-card.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                    Recharge card manufacturing
                    </h1>
                    <p className='white-text' style={{color:"#fff"}}>As the world’s number one recharge card manufacturer, we print the most advanced, secure and cost-effective pre-paid phone cards available with innovative value-added features to ensure the best recharge experience for mobile subscribers.</p>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                    <div class="col-md-6 col-lg-6">
                            <div className='card-scrch'>
                                <img src='images/card/card-02.png' />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                        <h2>Switch to the No.1 prepaid card</h2>
                            <div>
                                <p>Intelcom Limited represents a technological breakthrough in recharge card performance, delivering proven success across more than 2 of the most demanding global markets. End-users benefit from a seamless and hassle-free recharge experience, consistently enjoying reliable and rewarding results with every use.</p>

                            </div>
                            <ul class="simple-list- free-ass">
                            <li class="list-item-"><img src="images/check.png" alt=""/> Detailed evaluation of your current workloads.</li>
                            <li class="list-item-"><img src="images/check.png" alt=""/> Reinforced over-scratch protection to significantly reduce market complaints</li>
                            <li class="list-item-"><img src="images/check.png" alt=""/> Razor-sharp PIN definition for optimal use</li>
                            <li class="list-item-"><img src="images/check.png" alt=""/> Impenetrable tamper-proof panel to protect against fraud</li>
                            <li class="list-item-"><img src="images/check.png" alt=""/> Sleeker, lighter and more durable to reduce freight costs.</li>
                            </ul>
                        </div>
                     
                    </div>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape  bg-blue-gr">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                        
                          <div class="col-md-6 col-lg-6">
                        <h2>eSIM activation</h2>
                            <div>
                                <p>eSIM technology offers telcos numerous digital marketing and distribution opportunities. Its digital nature significantly reduces the time, resources, and costs associated with managing traditional SIM cards. Intelcom provides a comprehensive suite of activation solutions designed to improve telcos' activation rates, streamlining the process and maximizing efficiency.</p>
        

                            </div>
                        </div>
                          <div class="col-md-6 col-lg-6">
                        <div className='card-scrch'>
                                <img src='images/card/card-sim01.png' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                      <div class="col-md-6 col-lg-6">
                    <div className='card-scrch'>
                                <img src='images/card/s-card.jpg' />
                            </div>
                        </div>
                          <div class="col-md-6 col-lg-6">
                        <h2>SIM card manufacturing</h2>
                            <div>
                                <p>As a global top 10 SIM provider, Intelcom delivers a comprehensive, integrated SIM solution. Our cost-efficient services, including profile development, SIM production, technical support, data personalization, packaging, and fulfillment, are all seamlessly managed in-house, ensuring streamlined operations and exceptional quality control.</p>

                            </div>
                        </div>
                     
                    </div>
                </div>
            </section>
            <br/> <br/>
        </div>
    );
}

export default Scratchcard;
