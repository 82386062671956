
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import PartnerSider from '../partnerSider';
const ClientsLogo = [
    "./images/partner-img/microsoft.jpg",
    "./images/partner-img/redhat.png",
    "./images/partner-img/hp.png",
    "./images/partner-img/nutanix.png",
    "./images/partner-img/ubuntu.png",
    "./images/partner-img/lenovo.png",
    "./images/partner-img/checkpoint.png",
    "./images/partner-img/huawei.jpg",
]
function ManagedServices() {
    return (
        <div className='HardwareandSoftwares'>
            <br /> <br />
            <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/ict.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                        Managed services solutions
                    </h1>
                </div>
            </section>
            <br /> <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" >

                <Container >
                    <div class="row">
                        <div class="col-md-8">
                            <div className="intel-hardwere">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con">
                                            <p>
                                                Managed services solutions involve outsourcing the management and maintenance of IT systems to a third-party service provider. These services help businesses manage their infrastructure, ensuring optimal performance, security, and reliability. Managed service providers (MSPs) handle tasks such as network monitoring, server management, cybersecurity, and data backups, allowing companies to focus on their core business activities.</p>
                                        </div>
                                    </div>
                                    {/* <div class="col-md-4">
                                        <div className="intel-hardwere-img">
                                            <img src='./images/Resellers-of-IT-Hardware.webp' />
                                        </div>
                                    </div> */}
                                </div>
                                <div class="row">
                                    <h5 className='key-f'>Key Components of Managed Services:</h5>
                                    <div class="col-md-12">
                                
                                        <div className="intel-hardwere-con-h">
                                            <ul>
                                            <div class="row">
                                            <div class="col-md-6">
                                                <li>Intrusion Prevention System (IPS)</li>
                                                <li>Advanced Persistent Threat (APT) Detection</li>
                                                <li>Web Application Firewall (WAF)</li>
                                                <li>Distributed Denial of Service (DDoS) Protection</li>
                                                <li>Web Security Solutions</li>
                                                <li>Cloud Access Security Broker (CASB)</li>
                                                <li>Vulnerability Management</li>
                                                <li>Network Access Control (NAC)</li>
                                                <li>Endpoint Detection and Response (EDR)</li>
                                                <li>Antivirus Solutions</li>
                                                <li>Data Leakage Prevention (DLP)</li>
                                                <li>Data Encryption</li>
                                                </div>
                                                <div class="col-md-6">
                                               
                                                <li>Backup and Recovery</li>
                                                <li>Data Storage Solutions</li>
                                                <li>Server Security</li>
                                                <li>Server Load Balancing (SLB) / Global Server Load Balancing (GSLB)</li>
                                                <li>Application Delivery Controller (ADC)</li>
                                                <li>Wi-Fi Security</li>
                                                <li>Network Switching</li>
                                                <li>Identity Access Management (IAM)</li>
                                                <li>Privileged Access Management (PAM)</li>
                                                <li>Zero Trust Network Architecture</li>
                                                <li>Application Control</li>
                                                </div>
                                              </div>  
                                            </ul>
                                           
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <h5 className='key-f'>Threat Intelligence Services:</h5>
                                  
                                    <div class="col-md-12">
                               
                                        <div className="intel-hardwere-con-h">
                                            <ul>
                                            <div class="row">
                                            <div class="col-md-12">
                                                <li>Threat Hunting</li>
                                                <li>Digital Risk Protection</li>
                                                <li>Fraud Detection and Prevention</li>
                                                <li>Brand Protection</li>
                                               
                                                </div>
                                              
                                              </div>  
                                            </ul>
                                           
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <h5 className='key-f'>Security Services:</h5>
                                  
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con-h">
                                            <ul>
                                            <div class="row">
                                            <div class="col-md-12">
                                                <li>Data Aggregation and Correlation</li>
                                                <li>Security Information and Event Management (SIEM)</li>
                                                <li>Ticketing System Management</li>
                                                <li>Knowledge Base Development</li>
                                                <li>Threat Intelligence Integration</li>
                                                <li>Research and Development</li>
                                                <li>Reporting and Analytics</li>
                                                <li>Log Collection and Management</li>
                                                <li>Security Operations Centers (SOCs) Maintenance</li>
                                                <li>Incident Diagnosis and Resolution</li>
                                                <li>Fault Detection and Response</li>
                                                <li>Traffic Analysis and Monitoring</li>
                                                <li>Network Configuration Management</li>
                                                <li>Event Management and Monitoring</li>
                                                </div>
                                              
                                              </div>  
                                            </ul>
                                           
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="submit-form">
                                <h3 className='msg-h'>Drop a Message..</h3>
                                <div class="formbold-main-wrapper">

                                    <div class="formbold-form-wrapper">
                                        <form >
                                            <div class="formbold-mb-5">
                                                <label for="name" class="formbold-form-label"> Full Name </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Full Name"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="email" class="formbold-form-label"> Email Address </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="subject" class="formbold-form-label"> Subject </label>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    placeholder="Enter your subject"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="message" class="formbold-form-label"> Message </label>
                                                <textarea
                                                    rows="6"
                                                    name="message"
                                                    id="message"
                                                    placeholder="Type your message"
                                                    class="formbold-form-input"
                                                ></textarea>
                                            </div>

                                            <div>
                                                <button class="formbold-btn">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intel-hardwere-img">
                        <img src='./images/managed-services-solutions.webp' />
                    </div>

                </Container>
            </section>
            <br /><br />
            <section id="clients" class="clients section">
                <div class="container section-title" data-aos="fade-up">
                    <h2>Our Hardware Partnerships</h2>
                    <br /><br />
                    <PartnerSider ClientsLogo={ClientsLogo} />
                </div>
            </section>
            <br /><br />
        </div>
    );
}

export default ManagedServices;