import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./index.scss"
import Container from 'react-bootstrap/esm/Container';
function Header() {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);
    return (
        <div className={scroll ? "intelcom-header-nav" : "intelcom-header"}>
            <Container>
                <Navbar expand="lg" className="inteloom-nav"  >

                    <Navbar.Brand href="/" className='logo'><img src='./images/intelcom-colorful-logo.png' alt='logo' style={{ width: "130px" }} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className=" justify-content-center">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about">About</Nav.Link>

                            <NavDropdown title="Services" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/resellers-of-IT-Hardware-and-Softwares">Resellers of IT Hardware and Softwares</NavDropdown.Item>
                                <NavDropdown.Item href="/Cyber-security-solutions">
                                Cyber security solutions
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/managed-services">Managed Services</NavDropdown.Item>

                                <NavDropdown.Item href="/enterprise-resource-planning">
                                    Enterprise Resource Planning (ERP)
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/sim">SIM card</Nav.Link>
                            <Nav.Link href="/scratchcard">Scratch card</Nav.Link>
                            <Nav.Link href="/ictsolution">ICT solution</Nav.Link>
                            <Nav.Link href="/contact">Contact Us</Nav.Link>

                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
            </Container>
        </div>
    );
}

export default Header;