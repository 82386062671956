import React from 'react';
import "./about.scss"
import PartnerSider from '../components/partnerSider';
const ClientsLogo = [
  "./images/partner-img/microsoft.jpg",
  "./images/partner-img/redhat.png",
  "./images/partner-img/hp.png",
  "./images/partner-img/nutanix.png",
  "./images/partner-img/ubuntu.png",
  "./images/partner-img/lenovo.png",
  "./images/partner-img/checkpoint.png",
  "./images/partner-img/huawei.jpg",
]
const ClientsLogo2 = [
  "./images/partner-img/microsoft.jpg",
  "./images/partner-img/veeam.png",
  "./images/partner-img/kaspersky.jpg",
  "./images/partner-img/vmware.jpg",
  "./images/partner-img/redhat.png",
  "./images/partner-img/fortinet.png",
  "./images/partner-img/mcafee.jpg",
  "./images/partner-img/office365.png",
  "./images/partner-img/checkpoint.png",
]
function About() {
  return (
    <div>
       <br /> <br />
       <br /> 
      <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" style={{ background: `url('images/hero-bg8.jpg')no-repeat center center / cover` }}>
     
        <div class="container">
          <div class="row">
            <div class="col-md-7 col-lg-6">
              <div className='text-back'>
              <div class="hero-slider-content">
                <h2>Intelcom Limited: A Comprehensive Company Profile</h2>
                <p class="lead">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                  in the realm of Information and Communication Technologies (ICT) and
                  Telecommunication equipment and solutions. The company offers a diverse range
                  of ICT solutions and services, catering to the evolving needs of businesses and
                  individuals across Tanzania. With its strong foundation and expertise, Intelcom has
                  established itself as a trusted partner for various ICT and Telecommunication
                  requirements</p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <section id="clients" class="clients section">
        <div class="container section-title" data-aos="fade-up">
          <h2>Our Hardware Partnerships</h2>
          <br /><br />
          <PartnerSider ClientsLogo={ClientsLogo} />
        </div>
      </section>
      <br />
      <br />
      <section className="eni pt-100 ct-01 content-section division">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-md-12 aos-init aos-animate" data-aos="fade">
              <div class="txt-block right-column" >
                <h2 class=" text-center">Core Business Areas
                </h2>

              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-4">
              <div class="databus testing-gray aos-init aos-animate">
                <div>
                  <img src="images/img/testing/ai-based.png" alt="" />
                </div>
                <p class="text-center"><span> Managed Services</span><br /> Intelcom provides comprehensive managed services,
                  ensuring seamless and efficient operation of IT infrastructure. This includes
                  network management, server administration, cloud services, and helpdesk
                  support</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="databus testing-gray aos-init aos-animate" data-aos="fade-in">
                <div>
                  <img src="images/img/testing/pre-built.png" alt="" />
                </div>
                <p class="text-center"><span> Cybersecurity Solutions</span><br /> Recognizing the critical importance of data security,
                  Intelcom offers robust cybersecurity solutions to protect businesses from cyber
                  threats. These solutions encompass firewalls, intrusion detection systems,
                  antivirus protection, and data encryption</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="databus testing-gray aos-init aos-animate" data-aos="fade-in" >
                <div>
                  <img src="images/img/testing/zero-code.png" alt="" />
                </div>
                <p class="text-center"><span> Customized Software Solutions</span><br />Intelcom specializes in developing tailor-made
                  software solutions that align with specific business needs. These solutions can
                  range from enterprise resource planning (ERP) systems to mobile applications.</p>
              </div>
            </div>

          </div>
          <div class="row justify-content-center mt-auto mt-md-4">

            <div class="col-md-4">
              <div class="databus testing-gray aos-init" data-aos="fade-in" >
                <div>
                  <img src="images/img/testing/zero-defects.png" alt="" />
                </div>
                <p class="text-center"><span>Professional Services</span><br />The company offers a wide range of professional services,
                  including IT consulting, systems integration, and project management. These
                  services help clients optimize their IT operations and achieve their business goals.</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="databus testing-gray aos-init" data-aos="fade-in" >
                <div>
                  <img src="images/img/testing/zero-defects.png" alt="" />
                </div>
                <p class="text-center"><span>Supply of e-SIM/SIM Cards and Scratch Cards</span><br /> Intelcom distributes e-SIM/SIM
                  cards and scratch cards, providing convenient and reliable connectivity solutions
                  for individuals and businesses</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br/><br/>
      <section class="bg-blue-gr mt-5 mt-md-0 mt-lg-0 mt-xl-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h2 class="text-white">Supply of ICT Hardware, Infrastructure, and Software Solutions<br class="d-none d-md-none d-lg-block d-xl-block" /></h2>
              <img src="images/img/heading-line-dec-w.png" alt="" class="pb-2" />
              <p class="para1 text-white">Laptops,
                desktops, servers, networking equipment, other essential hardware components,
                Data center solutions, cloud computing services, network infrastructure,
                cybersecurity solutions, Operating systems (Windows, Linux), productivity software
                (Microsoft Office, Google Workspace), cybersecurity software (Kaspersky, Sophos),
                and other specialized software applications.</p>
            </div>
          </div>
        </div>
      </section>
      <br/><br/>
      <section id="clients" class="clients section">
        <div class="container section-title" data-aos="fade-up">
          <h2>Our Software Partnerships</h2>
       <br/><br/>
          <PartnerSider ClientsLogo={ClientsLogo2} />
        </div>
      </section><br/><br/>
      <br />
    </div>
  );
}

export default About;
